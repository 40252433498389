<template>
  <div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item>
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Download Response</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <!-- <span v-if="props.column.field === 'relationship'">
          {{ props.row.relationship == 'Other' ? props.row.relationshipOtherDetails : props.row.relationship }}
        </span> -->
        <!-- <span v-else-if="props.column.field === 'feedback'">
          {{ props.row.feedback.feedback }}
        </span>
        <span v-else-if="props.column.field === 'rating'">
          <b-form-rating
            v-model="props.row.feedback.rating"
            variant="warning"
            no-border
            class="mt-1 w-50 modal-stars"
            size="lg"
            disabled
          />
        </span> -->

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
  },
  data() {
    return {
      pageLength: 20,
      columns: [
        // {
        //   label: 'Kit-User',
        //   field: '_id',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search',
        //   },
        // },
        // {
        //   label: 'Account ID',
        //   field: 'accountID',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search',
        //   },
        // },
        {
          label: 'Name',
          field: this.getName,
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        // {
        //   label: 'Relationship to Account Holder',
        //   field: 'relationship',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search',
        //   },
        // },
        // {
        //   label: 'City',
        //   field: 'address.city',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search',
        //   },
        // },
        // {
        //   label: 'State',
        //   field: 'address.state',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search',
        //   },
        // },
        {
          label: 'Kit Type',
          field: 'kitType',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
          formatFn: kitType => kitType.replace(/^\w/, c => c.toUpperCase()),
        },
        {
          label: 'Country',
          field: 'country',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit Delivery Rating',
          field: 'feedback.kitDelivery.rating',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit Delivery feedback',
          field: 'feedback.kitDelivery.description',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Sample Collection Rating',
          field: 'feedback.sampleCollection.rating',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Sample Collection feedback',
          field: 'feedback.sampleCollection.description',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Reports Informative Rating',
          field: 'feedback.reportsInformative.rating',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Reports Informative feedback',
          field: 'feedback.reportsInformative.description',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Web App Experience Rating',
          field: 'feedback.webappExperience.rating',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Web App Experience feedback',
          field: 'feedback.webappExperience.description',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Improvement',
          field: 'feedback.improvement',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
      ],
      rows: [],
    }
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    getName(rowObj) {
      return `${rowObj.sampleCollectionData.firstName} ${rowObj.sampleCollectionData.lastName}`
    },
    getTableData() {
      this.showLoading()
      this.$http.get('/barcodes', {
        params: {
          queryParams: {
            columnFilters: { feedback: { $exists: true }, sampleCollectionData: { $exists: true } },
          },
        },
      }).then(response => {
        this.rows = response.data.list
      }).catch(this.handleError).finally(this.closeLoading)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
